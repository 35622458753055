import React from "react"
import styled from "styled-components"
import Headers from "../common/headers/Headers"
import { connect } from "react-redux"
import { changeLogoColor } from "../../state/app"
import getSingleImage from "../../utils/getSingleImage"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import getDateInProperFormat from "../../utils/getDateInProperFormat"
import { StyledLinkOnPicture } from "../common/buttons/ButtonLinkStyle"
import ButtonOnPictureNews from "../common/buttons/ButtonsOnPictureNews"
import getSlug from "../../utils/getSlug"

const ListOfNewsBannerWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.stringBlack};
	width: 100%;
	padding: 230px 35px 0;
	position: relative;
	
	@media (min-width: 1000px) {
		height: 100vh;
		min-height: 860px;
		padding: 290px 140px;
	}
`

const BannerHeader = styled(Headers)`
	font-size: ${({ theme }) => theme.headers.h6};
	line-height: ${({ theme }) => theme.lineHeight.line4};
	position: relative;
	display: table;
	
	@media (min-width: 1000px) {
		font-size: ${({ theme }) => theme.headers.h2};
		line-height: ${({ theme }) => theme.lineHeight.line19};
	}
	
	&:before {
		content: '';
    position: absolute;
    top: 0;
    width: 8px;
    height: 8px;
    right: -30px;
    background-color: ${({ theme }) => theme.colors.stringWhite};
  }
`

const Description = styled.p`
	color: ${({ theme }) => theme.colors.stringWhite};
	font-size: ${({ theme }) => theme.fontSizes.f14};
	line-height: ${({ theme }) => theme.lineHeight.line14};
	margin-top: 30px;
	margin-bottom:95px;
	
	@media (min-width: 1000px) {
		font-size: ${({ theme }) => theme.fontSizes.f13};
		line-height: ${({ theme }) => theme.lineHeight.line1};
		width: 460px;
	}
`

const NewsBannerWrapper = styled.div`
	margin-left: -35px;
	margin-right: -35px;
	
	@media (min-width: 1000px) {
		position: absolute;
		left: 0;
		width: 100%;
		bottom: -338px;
	}
`
const NewsBanner = styled.div`
	width:100%;
	height: 550px;
	position: relative;
	background-image: url(${props => props.back});
	background-position: center;
	background-size: cover;
	padding: 120px 35px 95px;
	z-index: 10;
	
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		background-color: ${({ theme }) => theme.colors.stringBlack};
		opacity: .4;
		width: 100%;
		height: 100%;
	}
	
	@media (min-width: 1000px) {
		height: 675px;
		width: 80%;
		padding: 100px 140px 140px;
	}
`

const BannerDescription = styled.div`
	color: ${({ theme }) => theme.colors.stringWhite};
	line-height: ${({ theme }) => theme.lineHeight.line14};
	font-size: ${({ theme }) => theme.fontSizes.f13};
	position: relative;
	padding: 35px 0 0;
	
	@media (min-width: 1000px) {
		padding: 40px 0 0;
		font-size: ${({ theme }) => theme.fontSizes.f10};
		line-height: ${({ theme }) => theme.lineHeight.line13};
		width: 100%;
	}
`

const DateInfo = styled.div`
	color:  ${({ theme }) => theme.colors.stringWhite};
	font-size: ${({ theme }) => theme.paragraphs.p4};
	line-height: ${({ theme }) => theme.lineHeight.line16};
	font-weight: ${({ theme }) => theme.font.bold};
	position: relative;
	
		&:before {
		content: '';
		width: 8px;
   	height: 8px;
   	position: absolute;
   	top: -20px;
   	left: 0;
   	background-color: ${({ theme }) => theme.colors.stringWhite};
}
`

const ButtonWrapper = styled.div`
	position: absolute;
	bottom: 125px;
	left: 35px;
	
	@media (min-width: 1000px) {
		bottom: 200px;
		left: 140px;
	}
`

class ListOfNewsBanner extends React.Component {

	render() {
		const { dispatch, activeSite } = this.props
		const readTextBtn = (this.props.translationsData && this.props.translationsData.tlumaczenieCzytaj) || ""
		dispatch(changeLogoColor(true))
		const bannerData = this.props.data && this.props.data.banerZNachodzacaAktualnoscia || []
		const header = (bannerData && bannerData[0] && bannerData[0].naglowek) || ""
		const description = (bannerData && bannerData[0] && bannerData[0].opis) || ""
		const getNews = (bannerData && bannerData[0] && bannerData[0].wybierzAktualnosc && bannerData[0].wybierzAktualnosc[0]) || ""
		const newsSlugBtn = getNews.slug || ""
		const createdDate = getNews.postDate || ""
		const newsBannerDescription = (getNews.aktualnoscBaner && getNews.aktualnoscBaner[0] && getNews.aktualnoscBaner[0].opis) || ""
		const newsBannerPhoto = (getNews.aktualnoscBaner && getNews.aktualnoscBaner[0] && getNews.aktualnoscBaner[0].zdjecie) || ""

		const dateInfo = getDateInProperFormat(activeSite, createdDate, true)
		return (
			<ListOfNewsBannerWrapper>
				<BannerHeader className="color-white font-weight-bold">{header}</BannerHeader>
				<Description>{description}</Description>
				<NewsBannerWrapper>
					<NewsBanner back={getSingleImage(newsBannerPhoto)}>
						<DateInfo>{dateInfo}</DateInfo>
						<BannerDescription dangerouslySetInnerHTML={getHtmlFromRedactorField(newsBannerDescription)}/>
						<ButtonWrapper>
							<StyledLinkOnPicture to={`/${getSlug(newsSlugBtn,activeSite)}`}>
								<ButtonOnPictureNews readTextBtn={readTextBtn}/>
							</StyledLinkOnPicture>
						</ButtonWrapper>
					</NewsBanner>
				</NewsBannerWrapper>
			</ListOfNewsBannerWrapper>

		)
	}
}

export default connect(state => ({
	isLogoWhite: state.app.isLogoWhite,
}), null)(ListOfNewsBanner)
