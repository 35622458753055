import React from "react"
import styled from "styled-components"
import {ButtonWhiteBorderBlue} from "../common/buttons/Button"
import Headers from "../common/headers/Headers"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import getSingleImage from "../../utils/getSingleImage"
import getSlug from "../../utils/getSlug"
import StyledLink from "../common/buttons/ButtonLinkStyle"

const SlideWrapper = styled.div`
	height: 100%;
	padding-top:130px;
	min-height: 100vh;
	width: auto;
	position: relative;

	@media (min-width: 1000px){ 
		display: flex;
	};
`

const SlideContent = styled.div`
	z-index: 1;
	display: block;
	padding-left:35px;

	@media( min-width: 1000px ) {
		width: 50%;
		//padding-left:140px;
	}
 @media( min-width: 1400px ) {
		width: 50%;
		padding-left:140px;
	}
	&.animate {
		transform: translateX(-1000px);
		transition: transform .8s;
		transition-delay: .1s;
	}
`

const ProductSVGWrapper = styled.div`
	display: none;
	
	@media (min-width: 1000px) {
		//display: flex !important;
		display: block;
		position: fixed;
		top: 15px;
		left: 428px;
		height: auto;
	}
	
	@media (min-width: 1401px) {
		left: 428px;
		right: unset;
	}
	
	@media (min-width: 1560px){ 
		top: 450px;
		left: unset;
		right: 562px;
		width: 60vw;
		transform:translate(50%, -50%); 
	}
	@media (min-width: 1710px){ 
		right: 566px;
	}
	@media (min-width: 1840px){ 
		right: 569px;
	}
	@media (min-width: 1920px){ 
		right: 571px;
	}
	@media (min-width: 2000px){ 
		right: 643px;
	}
	@media (min-width: 2170px){ 
		right: 647px;
	}
	@media (min-width: 2260px){ 
		right: 753px;
	}		 
	@media (min-width: 2560px){ 
		right: 761px;
	}

	&.animate {
		transform: translateY(-1000px);
		transition: transform .8s;
		
		@media (min-width: 1560px) { 
			transform:translate(50%, -1000px);
		}
	}
`

const ButtonWhiteBorderBlueExtended = styled(ButtonWhiteBorderBlue)`
	margin-bottom: 100px;
	z-index: 1000;
`

const ProductSVG = styled.img`
	width: 930px;
	height: auto;
	@media (min-width: 1560px) {
		width: 100%;
	}
`

const ProductSVGMobileWrapper = styled.div`
	width: 100%;
	
	@media (min-width: 1000px){ 
		display: none !important;
	}
`

const ProductSVGMobile = styled.img`
	width: auto;
	max-width: calc(100vw - 70px);
	margin: 0 auto;
`

const MainHeader = styled(Headers)`
	font-size: ${({ theme }) => theme.headers.h6};
	width: 100%;
	
	@media (min-width: 1000px) { 
		margin-top: 190px;
		width: 50%;
		font-size: ${({ theme }) => theme.headers.h2};
		line-height: 1;
	} 
	
	@media (min-width: 1900px) {
		font-size: ${({ theme }) => theme.headers.h2h};
	}
	
	@media (min-width: 2074px) {
		font-size: ${({ theme }) => theme.headers.h1s};
	}
`

const Paragraph = styled.h4`
	font-size: ${({ theme }) => theme.paragraphs.p2};
	line-height: 24px;
	width: 210px;
	margin: 35px 0;
		&.color-blue {
		color: ${({ theme }) => theme.colors.stringBlue}; 
	}
		&.font-weight-light {
		font-weight: ${({ theme }) => theme.font.light};
	}

	@media (min-width: 768px) {
		width: 380px;
		font-size: ${({ theme }) => theme.paragraphs.p1};
		margin: 10px 0 55px;
	}
	
	@media (min-width: 1900px) {
		font-size: ${({ theme }) => theme.headers.h7};
		width: 580px;
		line-height: 1.2;
	}
	
	@media (min-width: 2074px){
		width: 660px;
		font-size: ${({ theme }) => theme.headers.h7};
		line-height: 1.2;
	}
`


const HomeSlide = ({ slide,activeSite }) => {
	const btnLink = (slide.przyciskLink && slide.przyciskLink[0] && slide.przyciskLink[0].slug) || ""
const headline = `<h1>{slide.naglowek}</h1>`
	return (
		<SlideWrapper>
			<ProductSVGMobileWrapper>
				<ProductSVGMobile src={getSingleImage(slide.zdjecieMobile)}/>
			</ProductSVGMobileWrapper>
			<SlideContent className="slide-content">
				<MainHeader className="color-blue font-weight-bold"
										dangerouslySetInnerHTML={getHtmlFromRedactorField(slide.naglowek)}/>
				<Paragraph className="color-blue font-weight-light">{slide.opis}</Paragraph>
				<ButtonWhiteBorderBlueExtended as={StyledLink} to={`/${getSlug(btnLink, activeSite)}`}>
					<span>{slide.przyciskNapis}</span>
				</ButtonWhiteBorderBlueExtended>
			</SlideContent>
			<ProductSVGWrapper className="slide-image-wrapper">
				<ProductSVG className="slide-image" src={getSingleImage(slide.zdjecie)} alt={slide.zdjecie[0].altText}/>
			</ProductSVGWrapper>
		</SlideWrapper>
	)
}

export default HomeSlide
