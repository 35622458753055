import React from "react"
import styled from "styled-components"
import Arrow from "../../icons/arrow.svg"
import Paragraphs from "../common/paragraphs/Paragraphs"
import ButtonBlueBorderWhite from "../common/buttons/Button"
import getHtmlFromRedactorField from "../../utils/getHtmlFromRedactorField"
import getSingleImage from "../../utils/getSingleImage"
import pose from "react-pose"
import getSlug from "../../utils/getSlug"
import { Link } from "gatsby"

const ArrowButtonsWrapper = styled.div`
	display: none;
	
	@media (min-width: 1200px) {
		display: block;
		position: absolute;
		top: 25px;
		right: 25px;
	}
`

const ArrowButtonsWrapperMobile = styled.div`
	display: block;
	position: absolute;
	top: 75px;
	right: 25px;
	
	@media (min-width: 1000px) {
		right: 50px;
	}
	
	@media (min-width: 1200px) {
		display: none;
	}
`

const ArrowLeftButton = styled.button`
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;
	background-image: ${"url(" + Arrow + ")"};
	background-repeat: no-repeat;
	background-size: 20px 12px;
	width: 20px;
	height: 12px;
	background-color: transparent;
	outline: none;
	border: none;
	transition: .1s linear;

	&:hover {
		outline: none;
		transform: scale(1.5);
	}
`

const ArrowRightButton = styled(ArrowLeftButton)`
	transform: rotate(180deg);
	background-image: ${"url(" + Arrow + ")"};
	margin-left: 30px;
	
	&:hover {
		transform: rotate(180deg) scale(1.5);
	}
`

const ArrowMobilePose = pose(ArrowLeftButton)({
	normal: { transform: "rotate(-90deg)" },
	rotated: { transform: "rotate(-270deg)" },
})

const ArrowDownButton = styled(ArrowMobilePose)`
	background-image: ${"url(" + Arrow + ")"};
	margin-left: 30px;
`

const SliderContent = styled.div`
	display: none;
	min-height: 590px;
	margin-bottom: 150px;

	@media (min-width: 1200px) {
		display: flex;
		justify-content: flex-end;
		position: relative;
	};
	@media (max-width: 400px) {
		margin-bottom: 25px
	}
	
	//.run-animation {
	//	animation: square-animation 3s ease-in;
    //
	//@keyframes square-animation {
  	//0%   {opacity: 0;}
  	//100% {opacity: 1;}
	//}
	//}
`

const SliderContentMobile = styled(SliderContent)`
	display: flex;
	flex-direction: column;
	@media (min-width: 1200px) {
		display: none;
	}
`

const MobileContentWrapper = styled.div`
	padding: 0 35px;
`

const MobileHeadersWrapper = styled.div`
	padding: 50px 0 40px;
`

const HeadersExtended = styled.h5`
	font-family: ${({ theme }) => theme.font.headerFont};
	font-weight: ${({ theme }) => theme.font.thin}; 
		&.font-size-h10 {
		font-size: ${({ theme }) => theme.headers.h10};
	}
		&.color-white {
		color: ${({ theme }) => theme.colors.stringWhite};
	}
`

const ProductImg = styled.img`
	background-color: ${({ theme }) => theme.colors.stringBlack};
	display: none;

	@media (min-width: 1200px) {
		display: flex;
		position: absolute;
		left: 0;
		z-index:0;
		height: 100%;
	}
`
const ProductMobileImgWrapper = styled.div`
	margin-left: -35px;
	margin-right: -35px;
	margin-top: 60px;
`

const ProductMobileImg = styled.img`
	width: 100%;
	background-color: ${({ theme }) => theme.colors.stringBlack};
	display: flex;

	@media (min-width: 1200px) {
		display: none;
	}
`

const CenterProduct = styled.div`
	width: 100%;
	padding: 0;
	position: relative;
	margin-top:-1px;
	transition: background-color .5s linear;
	
	 &.blue {
		background-color: ${({ theme }) => theme.colors.stringBlue};
	}
	 &.black {
		background-color: ${({ theme }) => theme.colors.stringBlack};
	}
	 &.gray {
		background-color: ${({ theme }) => theme.colors.stringLightBlue};
	}

	@media (min-width: 1200px) {
		background-color: ${({ theme }) => theme.colors.stringBlue};
		width: 45%;
		max-width: 730px;
		padding: 75px;
		margin-top: 0;
	}
`

const SideProductsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	@media (min-width: 1200px) {
		width: 25%;
	}
`

const SideProduct = styled.div`
	opacity: 1;
	height: 50%;
	padding: 70px 35px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	transition: background-color .5s linear;

	&.blue {
		background-color: ${({ theme }) => theme.colors.stringBlue};
	}
	&.black {
		background-color: ${({ theme }) => theme.colors.stringBlack};
	}
	&.gray {
		background-color: ${({ theme }) => theme.colors.stringLightBlue};
	}
	
	@media (min-width: 1200px) {
		padding: 0 45px;
	}
`

const ProductMobilePose = pose.div({
	open: { height: "auto", opacity: 1 },
	close: { height: "1px", opacity: 0 },
})

const ProductExtended = styled(ProductMobilePose)`
	overflow: hidden;
`

const SlideContentWrap = styled.div`
	animation: opacity-animation .5s linear;
	&.opacity-0 {
		opacity: 0;
	}

	@keyframes opacity-animation {
  	0%   {opacity: 0;}
  	100% {opacity: 1;}
	}
`

const TopSlideContentWrap = styled(SlideContentWrap)`
	animation: opacity-animation .7s linear;

		@keyframes opacity-animation {
			 0%   {opacity: 0;}
			30%   {opacity: 0;}
			100% {opacity: 1;}
	}
`

const BottomSlideContentWrap= styled(SlideContentWrap)`
	animation: opacity-animation 1s linear;
		@keyframes opacity-animation {
  	50%   {opacity: 0;}
  		0%   {opacity: 0;}
  	100% {opacity: 1;}
	}
`

const MainHeader = styled.h3`
	font-size: ${({ theme }) => theme.headers.h5s};
	color: ${({ theme }) => theme.colors.stringWhite};
	font-weight: ${({ theme }) => theme.font.semiBold};

	span {
		position: relative;

		&:before {
			content:'';
			position: absolute;
			top: 5px;
			right: -30px;
			width: 8px;
			height: 8px;
			background-color:${({ theme }) => theme.colors.stringWhite};;
		}
	}
	
	@media (min-width: 1200px) {
		padding-top: 0;
	}
`

const Paragraph = styled(Paragraphs)`

	@media (min-width: 1200px) {
		margin-top: 40px;
	}
`

const ButtonBasicExtended = styled(ButtonBlueBorderWhite)`
	margin: 55px 0;
	width: 185px;
	
	&.blue {
		&:hover {
			color: ${({ theme }) => theme.colors.stringBlue};
		}
		&:before {
			background-color: ${({ theme }) => theme.colors.stringBlue};
		}
	}
	&.black {
		&:hover {
			color: ${({ theme }) => theme.colors.stringBlack};
		}
		&:before {
			background-color: ${({ theme }) => theme.colors.stringBlack};
		}
	}
	&.gray {
		&:hover {
			color: ${({ theme }) => theme.colors.stringLightBlue};
		}
		&:before {
			background-color: ${({ theme }) => theme.colors.stringLightBlue};
		}
	}
	
	@media (min-width: 1200px) {
		position: absolute;
		bottom: 10px;
	}
`

class ProductPresentation extends React.Component {

	state = {
		mainSlideIndex: 0,
		activeIndex: null,
	}

	setActiveRow = (key) => {
		this.setState({ activeIndex: key === this.state.activeIndex ? null : key })
	}

	render() {
		const activeSite = this.props.activeSite
		const slideData = (this.props.data.wizytowkaProduktow && this.props.data.wizytowkaProduktow) || ""
		const sliderDataTable = []

		slideData.forEach(item => {
			sliderDataTable.push({
				"subtitle": (item.produktWizytowka && item.produktWizytowka[0].dopisek) || "",
				"title": (item.produktWizytowka && item.produktWizytowka[0].nazwaProduktu) || "",
				"description": (item.produktWizytowka && item.produktWizytowka[0].opis) || "",
				"buttonName": (item.produktWizytowka && item.produktWizytowka[0].przyciskNapis) || "",
				"buttonSlug": item.slug || "",
				"photo": (item.produktWizytowka && item.produktWizytowka[0].zdjecie) || "",
			})
		})


		{ sliderDataTable[0] && Object.assign(sliderDataTable[0], sliderDataTable[0], { "bgColor": "blue" })}
		{ sliderDataTable[1] && Object.assign(sliderDataTable[1], sliderDataTable[1], { "bgColor": "black" })}
		{ sliderDataTable[2] && Object.assign(sliderDataTable[2], sliderDataTable[2], { "bgColor": "gray" })}

		let elementsToAnimate = []
		if (typeof window !== "undefined") {
			elementsToAnimate = document.getElementsByClassName('to-animate');
		}

		function animateOpacity() {
			Array.from(elementsToAnimate).forEach((item) => {
				item.style.animation = 'none';
				item.classList.add("opacity-0")
				setTimeout(function() {
					item.style.animation = '';
					item.classList.remove("opacity-0")
				}, 10);
			})
		}

		function slideRight() {
			if (mainSlideIndex < sliderDataTable.length - 1) {
				this.setState({ mainSlideIndex: mainSlideIndex + 1 })
			} else this.setState({ mainSlideIndex: 0 })
			animateOpacity();

		}


		function slideLeft() {
			if (mainSlideIndex > 0) {
				this.setState({ mainSlideIndex: mainSlideIndex - 1 })
			} else this.setState({ mainSlideIndex: sliderDataTable.length - 1 })

			animateOpacity();
		}



		function getSlideIndex(index) {
			if (index < 0) {
				return sliderDataTable.length - 1
			} else if (index > sliderDataTable.length - 1) {
				return 0
			} else return index
		}

		const mainSlideIndex = getSlideIndex(this.state.mainSlideIndex)
		const sideTopIndex = getSlideIndex(this.state.mainSlideIndex - 1)
		const sideBottomIndex = getSlideIndex(this.state.mainSlideIndex + 1)

		const mobileActiveBtnLink = this.state.activeIndex !== null ? getSlug(sliderDataTable[this.state.activeIndex ].buttonSlug, activeSite) : ""

		return (
			<>
				<SliderContent>
					<ProductImg src={getSingleImage(sliderDataTable[mainSlideIndex].photo)} alt={sliderDataTable[mainSlideIndex].photo[0].altText}/>
					<CenterProduct className={sliderDataTable[mainSlideIndex].bgColor}>
						<ArrowButtonsWrapper>
							<ArrowLeftButton onClick={slideLeft.bind(this)}/>
							<ArrowRightButton onClick={slideRight.bind(this)}/>
						</ArrowButtonsWrapper>
						<SlideContentWrap className="to-animate">
						<HeadersExtended
							className="color-white font-size-h10"> {sliderDataTable[mainSlideIndex].subtitle} </HeadersExtended>
						<MainHeader className="color-white font-size-h4 font-weight-semiBold ">

							{sliderDataTable[mainSlideIndex].title}
							<span />
						</MainHeader>
						<Paragraph as="div" className="color-white font-size-p2 font-weight-regular "
											 dangerouslySetInnerHTML={getHtmlFromRedactorField(sliderDataTable[mainSlideIndex].description)}/>
						<ButtonBasicExtended as={Link} to={`/${getSlug(sliderDataTable[mainSlideIndex].buttonSlug, activeSite)}`}
							className={sliderDataTable[mainSlideIndex].bgColor}> {sliderDataTable[mainSlideIndex].buttonName} </ButtonBasicExtended>
						</SlideContentWrap>
						<div>
							<ProductMobileImg src={getSingleImage(sliderDataTable[mainSlideIndex].photo)}  alt={sliderDataTable[mainSlideIndex].photo[0].altText}/>
						</div>
					</CenterProduct>

					<SideProductsWrapper>
						<SideProduct className={sliderDataTable[sideTopIndex].bgColor}>
							<TopSlideContentWrap className="to-animate">
								<HeadersExtended
									className="color-white font-size-h10">{sliderDataTable[sideTopIndex].subtitle}</HeadersExtended>
								<MainHeader
									className="color-white font-size-h5s font-weight-semiBold">
									{sliderDataTable[sideTopIndex].title}<span /></MainHeader>
							</TopSlideContentWrap>
						</SideProduct>
						<SideProduct className={sliderDataTable[sideBottomIndex].bgColor}>
							<BottomSlideContentWrap  className="to-animate">
								<HeadersExtended
									className="color-white font-size-h10">{sliderDataTable[sideBottomIndex].subtitle}</HeadersExtended>
								<MainHeader
									className="color-white font-size-h5s font-weight-semiBold">
								{sliderDataTable[sideBottomIndex].title}<span /></MainHeader>
							</BottomSlideContentWrap>
						</SideProduct>
					</SideProductsWrapper>
				</SliderContent>

				<SliderContentMobile>
					{sliderDataTable.map((row, key) => (
						<CenterProduct className={row.bgColor} key={key}>
							<ArrowButtonsWrapperMobile>
								<ArrowDownButton onClick={() => this.setActiveRow(key)}
																 pose={key === this.state.activeIndex ? "rotated" : "normal"}/>
							</ArrowButtonsWrapperMobile>
							<MobileContentWrapper onClick={() => this.setActiveRow(key)}>
								<MobileHeadersWrapper>
									<HeadersExtended
										className="color-white font-size-h10 line-height-3"> {row.subtitle}
									</HeadersExtended>
									<MainHeader className="color-white font-size-h6s font-weight-semiBold line-height-4">

							{row.title}
							<span />
									</MainHeader>
								</MobileHeadersWrapper>
							</MobileContentWrapper>
							<ProductExtended pose={key === this.state.activeIndex ? "open" : "close"}>
								<MobileContentWrapper>
									<Paragraph as="div" className="color-white font-size-p2 font-weight-regular"
														 dangerouslySetInnerHTML={getHtmlFromRedactorField(row.description)}/>
									<ButtonBasicExtended as={Link} to={`/${mobileActiveBtnLink}`}
										className={row.bgColor}> {row.buttonName}
									</ButtonBasicExtended>
								</MobileContentWrapper>
								<ProductMobileImgWrapper>
									<ProductMobileImg src={getSingleImage(row.photo)}  alt={row.photo[0].altText}/>
								</ProductMobileImgWrapper>
							</ProductExtended>
						</CenterProduct>
					))}
				</SliderContentMobile>
			</>
		)
	}
}

export default ProductPresentation
