import React from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { connect } from "react-redux"
import { changeLang, toggleMenu } from "../../state/app"
import getSlug from "../../utils/getSlug"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"

const CheckboxWrapper = styled.div`
	position: fixed;
	visibility: hidden;
	opacity: 0;
	z-index: 1002;
	top: 14px;
	left: 20px;
	transition-delay: .2s;
	transition:  opacity .8s linear;

	@media (min-width: 1000px){
		right:20px;
		left: unset;
	};
	
	&.visible {
		visibility: visible;
		opacity: 1;
	}
	
	.customControl {
		background-color: transparent;
		border: 2px solid ${({ theme }) => theme.colors.stringBlue};
		border-radius: 7px;
		width: 30px;
		height: 30px;
		padding: 0;
		font-size: ${({ theme }) => theme.fontSizes.f20};
		color: ${({ theme }) => theme.colors.stringWhite};
		font-weight: ${({ theme }) => theme.font.bold};
		text-transform: uppercase;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: visible;
		
		&:before {
			content: '';
			position: absolute;
			bottom: -4px;
			width: 8px;
			height: 8px;
			right: 4px;
			background-color: ${({ theme }) => theme.colors.stringBlack};
		}
	}
	
	.customMenu {
		background-color: transparent;
		font-size: ${({ theme }) => theme.fontSizes.f20};
		font-weight: ${({ theme }) => theme.font.bold};
		border: 2px solid ${({ theme }) => theme.colors.stringBlue};
		border-radius: 7px;
		padding: 0;
		text-transform: uppercase;
		display: flex;
		flex-direction: column;
		align-items: center;
	
		.Dropdown-option {
			color: ${({ theme }) => theme.colors.stringWhite};
			padding: 8px 0;
			
			&:hover {
				background-color: transparent;
				color: ${({ theme }) => theme.colors.stringBlue};
			}
		}
		.is-selected {
		background-color: transparent;
		}
	}
	
	.customArrow {
		display: none;
	}
`

const options = [
	"pl",
	"en",
]

class ChangeLanguage extends React.Component {

	constructor(props) {
		super(props)
	}

	pathToNavigate = ''
	actualPath = ''
	otherLang = ''
	tempActiveLang = ''

	handleChange = (selectedOption, dispatch, pathToNavigate, lang, isMenuVisible) => {
		if (selectedOption.value !== lang) {
			window.localStorage.setItem("appUILang", selectedOption.value)
			dispatch(changeLang(selectedOption.value))
			{
				isMenuVisible && dispatch(toggleMenu(!isMenuVisible))
			}
			navigate('/' + pathToNavigate);
		}
	}


	setStateToActiveSite = (dispatch, site) => {
		if (site === "stringEn") {
			dispatch(changeLang(options[1]))
		} else {
			dispatch(changeLang(options[0]))
		}
	}

  changeLangLocalStorage = () => {
		const { pageContext, lang, dispatch } = this.props
		const activeSite = pageContext.site

		if (typeof window !== "undefined") {
			const pathname = window?.location?.pathname || '';
			this.actualPath = pathname.replace(/^\/|\/$/g, '')

			if (activeSite === "stringEn") {
				this.tempActiveLang = 'en'
				this.otherLang = 'pl'
			} else {
				this.tempActiveLang = 'pl'
				this.otherLang = 'en'
			}

			const pathToNavigateArray = { [this.tempActiveLang]: this.actualPath, [this.otherLang]: this.pathToNavigate }
			const localStorageLang = window.localStorage.getItem('appUILang');
			const language = localStorageLang && localStorageLang !== 'undefined' ? localStorageLang : window.navigator.language;

			if (language.includes('pl')) {
				navigate('/' + pathToNavigateArray.pl);
				this.setStateToActiveSite(dispatch, 'default');
				window.localStorage.setItem('appUILang', 'pl');
			} else if (language.includes('en') && lang !== 'en') {
				navigate('/' + pathToNavigateArray.en);
				this.setStateToActiveSite(dispatch, 'stringEn');
				window.localStorage.setItem('appUILang', 'en');
			}
		}
	}

	componentDidMount() {
		this.changeLangLocalStorage();
}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.lang !== prevProps.lang) {
			this.changeLangLocalStorage();
		}
	}

	render() {
		const { pageContext, isMenuVisible, isMenuSticky, isMobile, lang, dispatch, firstOtherLangPathArray } = this.props
		const activePageId = pageContext.id
		const firstOtherLangSite = pageContext.firstOtherLangSite
		const otherLangUrlArray = []

		firstOtherLangPathArray.forEach(item => {
				const id = item.id
				const slug = getSlug(item.slug, firstOtherLangSite)
				const obj = { id, slug }
				otherLangUrlArray.push(obj)
			},
		)

		const otherLangUrlObject = otherLangUrlArray.reduce((prev, curr) => {
			prev[curr.id] = curr.slug
			return prev
		}, {})

		this.pathToNavigate = otherLangUrlObject[activePageId]


		return (
			<>
				<CheckboxWrapper className={((!isMobile && !isMenuSticky) || isMenuVisible) ? "visible" : ""}>
					<Dropdown controlClassName='customControl' menuClassName='customMenu' arrowClassName='customArrow'
										options={options} value={lang}
										onChange={(selectedOption) => this.handleChange(selectedOption, dispatch, this.pathToNavigate, lang, isMenuVisible)}
										placeholder=""/>
				</CheckboxWrapper>
			</>
		)
	}
}

export default connect(state => ({
	lang: state.app.lang,
	isMenuVisible: state.app.isMenuVisible,
	isMenuSticky: state.app.isMenuSticky,
	isMobile: state.app.isMobile,
}), null)(ChangeLanguage)

