import React from "react"
import styled from "styled-components"
import { BlackQube } from "../common/qube/Qube"
import Headers from "../common/headers/Headers"
import ButtonOnPictureNews from "../common/buttons/ButtonsOnPictureNews"
import getSingleImage from "../../utils/getSingleImage"
import getSlug from "../../utils/getSlug"
import StyledLink from "../common/buttons/ButtonLinkStyle"
import { Link } from "gatsby"
import getDateInProperFormat from "../../utils/getDateInProperFormat"

const NewsBox = styled.div`
  //min-height: 780px;
  background-color: white;
`

const NewsHeader = styled(Headers)`
  margin: 0 140px 89px 140px;
  position: relative;
  padding: 0;
  font-size: ${({ theme }) => theme.headers.h8};

  @media (max-width: 1400px){
    margin: 0 35px 89px 35px;
  };

  @media (max-width: 375px){
    margin: 0 0 0 35px;
  };

  :before{
    content: "";
    width: 7px;
    height: 7px;
    background-color: black;
  }
`

const Qube = styled(BlackQube)`
  top: -30px;
  left: 4px
`
const ReturnButton = styled(StyledLink)`
	color: ${({ theme }) => theme.colors.stringBlue};
	width: 221px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.stringBlue};
	font-size: ${({ theme }) => theme.paragraphs.p3};
	padding: 0 0 5px 0;
	cursor: pointer;
	margin: 50px 140px 100px 140px;
	display: flex;

	@media (max-width: 1400px){
		margin: 0 35px 89px 35px;
	};

	@media (max-width: 375px){
		display: none;
		margin: 50px 35px 100px 35px;
	}

`
const ReturnButtonRwd = styled(StyledLink)`
	color: ${({ theme }) => theme.colors.stringBlue};
	width: 221px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.stringBlue};
	font-size: ${({ theme }) => theme.paragraphs.p3};
	padding: 0 0 5px 0;
	cursor: pointer;
	margin: 50px 140px 100px 140px;
	display: none;

	@media (max-width: 1400px){
		margin: 0 35px 89px 35px;
	};

	@media (max-width: 375px){
		display: flex;
		margin: 0 35px 100px 35px;
	};

`
const NewsBoxWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  width: 100%;
  margin: 0 10px;

  @media (max-width: 770px){
	margin: 0;
	flex-flow: wrap;
  };
`

const WrapperForOneBox = styled.div`
	width: 375px;
	margin: 0 10px;
	text-decoration: none;
	&:hover .hover-elemnt{
		color: ${({ theme }) => theme.colors.stringBlue};
	};

	&:hover .hover-blenda{
		background-image: none;
		cursor: pointer;
		background-color: ${({ theme }) => theme.colors.stringBlue};
  };
  
	&:hover .hover-image{
		display: none
	};

	// &:hover .hover-button :before{
	// 	background-image: none;
	// 	cursor: pointer;
	// 	background-color: ${({ theme }) => theme.colors.stringBlue};
  // };
  //
	&:hover {
		.hover-button {
			display: flex;
			background-color: ${({ theme }) => theme.colors.stringWhite};
			&:before {
				background-image: none;
				cursor: pointer;
				background-color: ${({ theme }) => theme.colors.stringBlue};
  		}
		}
		.left {
			width: 16px;
		}
		.right {
				width: 155px;
		}
		.content{
			color: ${({ theme }) => theme.colors.stringBlue};
		}
	}
}
	

	@media (max-width: 700px){
		margin: 0;
		width: 100%;
  };
  
	@media (max-width: 447px){
		width: 100%;
	};
`

const NewsParagraph = styled.div`
  padding: 26px 0 0 0;
  
	@media (max-width: 800px){
		padding: 26px 35px 0 35px;
	}
`

const NewsData = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.stringLightBlue};
  margin: 23px 0;
  font-size: ${({ theme }) => theme.paragraphs.p3};
`

const NewsTitle = styled.h4`
  width: 90%;
  color: ${({ theme }) => theme.colors.stringBlack};
  margin: 21px 0 60px 0;
  font-size: ${({ theme }) => theme.headers.h8};
  	&.font-weight-medium {
		font-weight: ${({ theme }) => theme.font.medium};
	}
		&.font-size-h8 {
		font-size: ${({ theme }) => theme.headers.h8};
	}
`

const DivWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 375px;
  font-size: ${({ theme }) => theme.headers.h8};
  position: relative;
  
  @media (max-width: 1325px){
    max-width: 100%;
  };

  @media (max-width: 800px){
    max-width: 100%;
  };
`
const StyleDrawing = styled.img`
  width: 100%;
  height: 100%;
  padding: 0;
	margin: 0;
	background-image: url(${props => props.back});
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
  
  @media (max-width: 800px){
    width: 100%;
  };
`

const Container = styled.div`
  margin: 0 140px;
  display: flex;
  flex-flow: row;

  @media (max-width: 1400px){
    margin: 0 35px;
  };

  @media (max-width: 700px){
    margin: 0
  };
`

class News extends React.Component {
	render() {
		const activeSite = this.props.activeSite
		const newsData = (this.props.data.wizytowkaAktualnosci && this.props.data.wizytowkaAktualnosci[0].aktualnosciWybierz) || []
		const readTextBtn = (this.props.translationsData && this.props.translationsData.tlumaczenieCzytaj) || ""
		const newsDataTable = []

		newsData.forEach(item => {
			newsDataTable.push({
				"imgUrl": (item.aktualnoscWizytowka && item.aktualnoscWizytowka[0].zdjecie) || "",
				"textUrl": (item.title) || "",
				"dateCreate": getDateInProperFormat(activeSite, item.postDate) || "",
				"slug": (item.slug && getSlug(item.slug, activeSite)) || "",

			})
		})


		const newsDataTittle = (this.props.data.wizytowkaAktualnosci && this.props.data.wizytowkaAktualnosci[0].tytul) || ""

		const returnButton = (this.props.data.wizytowkaAktualnosci && this.props.data.wizytowkaAktualnosci[0].zobaczWszystkieAktualnosci) || ""

		const returnButtonSlug = (this.props.data.wizytowkaAktualnosci && this.props.data.wizytowkaAktualnosci[0].podstrony[0].slug) || ""

		const langReturnButtonSlug = getSlug(returnButtonSlug, activeSite)

		return (
			<NewsBox>
				<NewsHeader className="font-size-h9 color-black font-weight-bold">
					<Qube/>
					{newsDataTittle}
				</NewsHeader>
				<ReturnButtonRwd to={`/${langReturnButtonSlug}`} className="font-size-h11 color-blue font-weight-light">
					{returnButton}
				</ReturnButtonRwd>
				<Container>
					<NewsBoxWrapper>
						{newsDataTable.map((news, key) =>
							<WrapperForOneBox key={key} as={Link} to={`/${news.slug}`}>
								<DivWrapper className="hover-blenda">
									<StyleDrawing back={getSingleImage(news.imgUrl)} className="hover-image"/>
									{/*<StyledLinkOnPicture>*/}
									<ButtonOnPictureNews readTextBtn={readTextBtn} className="hover-button"/>
									{/*</StyledLinkOnPicture>*/}
								</DivWrapper>
								<NewsParagraph>
									<NewsData className="font-size-p3 font-weight-light">{news.dateCreate}</NewsData>
									<NewsTitle
										className="hover-elemnt font-weight-medium color-black font-size-h8">{news.textUrl}</NewsTitle>
								</NewsParagraph>
							</WrapperForOneBox>,
						)
						}
					</NewsBoxWrapper>
				</Container>
				<ReturnButton to={`/${langReturnButtonSlug}`} className="font-size-h11 color-blue font-weight-light">
					{returnButton}
				</ReturnButton>
			</NewsBox>
		)
	}
}

export default News
