import React from "react"
import styled from "styled-components"

export const Headers = styled.div`
	font-family: ${({ theme }) => theme.font.headerFont};

	&.font-size-h1h {
		font-size: ${({ theme }) => theme.headers.h1h};
	}
	
	&.font-size-h1 {
		font-size: ${({ theme }) => theme.headers.h1};
	}
		&.font-size-h2 {
		font-size: ${({ theme }) => theme.headers.h2};
	}
		&.font-size-h3 {
		font-size: ${({ theme }) => theme.headers.h3};
	}
		&.font-size-h4 {
		font-size: ${({ theme }) => theme.headers.h4};
	}
		&.font-size-h5 {
		font-size: ${({ theme }) => theme.headers.h5};
	}
		&.font-size-h5s {
		font-size: ${({ theme }) => theme.headers.h5s};
	}
		&.font-size-h6 {
		font-size: ${({ theme }) => theme.headers.h6};
	}
		&.font-size-h6s {
		font-size: ${({ theme }) => theme.headers.h6s};
	}
		&.font-size-h7 {
		font-size: ${({ theme }) => theme.headers.h7};
	}	
	&.font-size-h8 {
		font-size: ${({ theme }) => theme.headers.h8};
	}
		&.font-size-h9 {
		font-size: ${({ theme }) => theme.headers.h9};
	}	
	&.font-size-h10h {
		font-size: ${({ theme }) => theme.headers.h10h};
	}
	&.font-size-h10 {
		font-size: ${({ theme }) => theme.headers.h10};
	}
		&.font-size-h11 {
		font-size: ${({ theme }) => theme.headers.h11};
	}
		&.font-size-h12 {
		font-size: ${({ theme }) => theme.headers.h12};
	}
	
	
	&.color-white {
		color: ${({ theme }) => theme.colors.stringWhite};
	}
	&.color-black {
		color: ${({ theme }) => theme.colors.stringBlack};
	}
	&.color-blue {
		color: ${({ theme }) => theme.colors.stringBlue};
	}
	

	&.font-weight-thin {
		font-weight: ${({ theme }) => theme.font.thin};
	}
	&.font-weight-light {
		font-weight: ${({ theme }) => theme.font.light}; 
	}
	&.font-weight-regular {
		font-weight: ${({ theme }) => theme.font.regular};
	}
	&.font-weight-medium {
		font-weight: ${({ theme }) => theme.font.medium};
	}
	&.font-weight-semiBold {
		font-weight: ${({ theme }) => theme.font.semiBold};
	}
	&.font-weight-bold {
		font-weight: ${({ theme }) => theme.font.bold};
	}
	&.font-weight-extraBold {
		font-weight: ${({ theme }) => theme.font.extraBold};
	}
	&.font-weight-black {
		font-weight: ${({ theme }) => theme.font.black};
	}
	
	 &.line-height-3 {
		line-height: ${({ theme }) => theme.lineHeight.line3};
	}
	 &.line-height-4 {
		line-height: ${({ theme }) => theme.lineHeight.line4};
	}
	 &.line-height-7 {
		line-height: ${({ theme }) => theme.lineHeight.line7};
	}
	 &.line-height-8 {
		line-height: ${({ theme }) => theme.lineHeight.line8};
	}
	&.line-height-12 {
		line-height: ${({ theme }) => theme.lineHeight.line12};
	}
	&.line-height-17 {
		line-height: ${({ theme }) => theme.lineHeight.line17};
	}
`


export const ProductNumber = styled(Headers)`
	@media (min-width: 1000px) {
		padding-top: 5px;
  }
`
export const ProductHeader = styled.h2`
	width: 250px;
	padding-top: 30px;
	text-align: end;
	&.font-size-h6 {
		font-size: ${({ theme }) => theme.headers.h6};
	}
	&.font-weight-semiBold {
		font-weight: ${({ theme }) => theme.font.semiBold};
	}
	&.color-white {
		color: ${({ theme }) => theme.colors.stringWhite};
	}

	
	@media (min-width: 1000px) {
		padding-top: 0;
		width: 100%;
  }
`

export const ProductDescription = styled(Headers)`
	font-family: ${({ theme }) => theme.font.headerFont};
	padding-top: 45px;
	text-align: end;	
	
	@media (min-width: 1000px) {
		padding-left: 30%;
  }
`

export default Headers
